import React from 'react';
import { AnswerType, ResultType } from '@sapphicsavvy/business';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import BarSlider from '../../../helperComponents/BarSlider';
import CorrectAnswerIcon from '../../../../resources/svgComponents/Quarter/CorrectAnswerIcon';
import WrongAnswerIcon from '../../../../resources/svgComponents/Quarter/WrongAnswerIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import { capitalize } from '../../../../utils/shared';

type InfoCardProps = {
    type: AnswerType,
    title: string
    userValue: number
    correctValue: number
    userImage: React.ReactChild
    correctImage: React.ReactChild
    userColor: string
    correctColor: string,
    resultType: ResultType,
    min?: number,
    max?: number,
}

export default function InfoCard(props: InfoCardProps) {
    return (
        <ShadowView style={styles.container}>
            <Text style={styles.title}>{capitalize(props.title)}</Text>
            <View style={styles.section}>
                {(props.resultType === ResultType.exact || props.resultType === ResultType.inWiggle) ? <CorrectAnswerIcon /> : <WrongAnswerIcon />}
                <Spacer width={10} />
                {props.userImage}
                <View style={styles.sectionBar}>
                    <BarSlider disableLimit colors={[props.userColor, props.userColor]} minValue={props.min || 0} maxValue={props.max || 100} value={props.userValue} enabled={false} />
                </View>
                <Text style={[styles.sectionTitleText, { color: props.userColor }]}>{props.userValue}{props.type === AnswerType.percentage ? '%' : ''}</Text>
            </View>
            <View style={styles.section}>
                <CorrectAnswerIcon />
                <Spacer width={10} />
                {props.correctImage}
                <View style={styles.sectionBar}>
                    <BarSlider disableLimit colors={[props.correctColor, props.correctColor]} minValue={props.min || 0} maxValue={props.max || 100} value={props.correctValue} enabled={false} />
                </View>
                <Text style={[styles.sectionTitleText, { color: props.correctColor }]}>{props.correctValue}{props.type === AnswerType.percentage ? '%' : ''}</Text>
            </View>
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 15,
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#000000F4',
    }]),
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
    sectionBar: {
        flex: 1,
        marginHorizontal: 10,
    },
    sectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '700',
    }]),
});
