import { z } from 'zod';
import { GroupBonuses, AnswerType } from '../../../entities';
import { DateInputSchema, StringSchema } from '../../common';

// Define the base schema for answer options
const BaseAnswerOptionsSchema = z.object({
    min: z.number().default(0).optional(),
    max: z.number().optional(),
    increment: z.number().default(1),
});

// Define the schema for percentage answer options
export const PercentageAnswerOptionsSchema =
    BaseAnswerOptionsSchema.partial().extend({
        max: z.number().default(100).optional(),
    });

// Define the schema for numeric answer options
export const NumericAnswerOptionsSchema = BaseAnswerOptionsSchema.extend({
    max: z.number().default(10).optional(),
});

export const AnswerOptionsSchema = z.object({
    min: z.number().default(0).optional(),
    max: z
        .number()
        .int()
        .min(1, { message: 'Maximum should be greater than 0' })
        .default(100)
        .optional(),
    increment: z
        .number()
        .int()
        .min(1, { message: 'Increment should be greater than 0' })
        .default(1)
        .optional(),
    strictMax: z.boolean().default(false).optional(),
    evaluateNoAnswer: z.boolean().default(false).optional(),
});

export const QuestionSchema = z.object({
    id: z.string().optional(),
    coreQuestion: z
        .string()
        .min(1, { message: "Core question can't be empty" })
        .trim(),
    sapphicQuestion: z
        .string()
        .min(1, { message: "Sapphic question can't be empty" })
        .trim(),
    answerType: z.nativeEnum(AnswerType),
    answerOptions: AnswerOptionsSchema,
    groupBonusType: z.nativeEnum(GroupBonuses),
    hasIdealBonus: z.boolean(),
    imageUrl: StringSchema.optional(),
    fact: z.string().optional(),
});

export const CreateQuarterInputSchema = z.object({
    quarterNumber: z.number().min(1).max(4),
    setNumber: z.number().min(1),
    startDate: DateInputSchema,
    questions: z.array(QuestionSchema).max(4).optional(),
    isDraft: z.boolean(),
});

export const UpdateQuarterInputSchema = CreateQuarterInputSchema.extend({
    id: z.string(),
});

export const DeleteQuarterInputSchema = z.object({
    id: z.string(),
});

export const UpdateFactsInputSchema = z.object({
    quarterId: z.string(),
    data: z.array(
        z.object({
            questionId: z.string(),
            fact: z.string(),
        }),
    ),
});

export const PublishQuarterInputSchema = z.object({
    quarterId: z.string(),
});

export const GetQuarterInputSchema = z.object({
    id: z.string(),
});

export const QuestionPreviewInputSchema = z.object({
    question: z.string(),
});
