import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, Image, Platform, StyleSheet, Text, View } from 'react-native';
import { capitalize, getNumberOfSteps, getTopInset } from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import Stepper from '../../../helperComponents/Stepper';
import { useSignedInContext } from '../../../../context/SignedInContext';
import CirculaireSlider from '../../../helperComponents/CirculaireSlider';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import { AgeGroup, AnswerType, GroupBonuses, QuestionToPlay, UserIdentity } from '@sapphicsavvy/business';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { Answer } from '../../../../navigations/RootNavigator';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import BarSlider from '../../../helperComponents/BarSlider';

type SliderCardProps = {
    title: string
    value: number,
    onChange?: (value: number) => void,
    steps?: number,
    min?: number,
    max?: number,
    answerType: AnswerType
}

function SliderCard({ title, value, onChange, steps = 5, min = 0, max = 100, answerType }: SliderCardProps) {
    return (
        <ShadowView style={styles.sliderCard}>
            <Text style={styles.sliderCardText}>{capitalize(title)}</Text>
            <View>
                {answerType === AnswerType.numeric ?
                    <BarSlider
                        colors={[mbApplyTransparency('#FCAF41', 0.95), mbApplyTransparency('#FCAF41', 0.95)]}
                        value={value}
                        minValue={min}
                        maxValue={max}
                        steps={steps}
                        onChange={onChange}
                        disableLimit
                        size={14}
                    />
                    :
                    <CirculaireSlider
                        colors={[mbApplyTransparency('#FCAF41', 0.95), mbApplyTransparency('#FCAF41', 0.95)]}
                        percentage={value}
                        size={150}
                        onChange={onChange}
                        step={steps}
                    />
                }
                <Text style={[styles.sliderCardValue, answerType === AnswerType.numeric && { marginTop: 5 }]}>{value}{answerType === AnswerType.numeric ? '' : '%'}</Text>
                <View style={styles.sliderCardBtns}>
                    <ShadowRoundedButton
                        size={44}
                        borderWidth={10}
                        icon={<MinusSignIcon size={15} />}
                        onPress={() => onChange?.(Math.max(value - steps, min))}
                    />
                    <Spacer width={15} />
                    <ShadowRoundedButton
                        size={44}
                        borderWidth={10}
                        icon={<PlusSignIcon size={15} />}
                        onPress={() => onChange?.(Math.min(value + steps, max))}
                    />
                </View>
            </View>
        </ShadowView>
    );
}

type GroupKey = keyof typeof UserIdentity | keyof typeof AgeGroup

const GroupState: Record<GroupKey, number> = {
    lateTeens: 85,
    '20s': 85,
    '30s': 85,
    '40s': 85,
    '50s+': 85,
    lesbian: 85,
    bisexual: 85,
    queer: 85,
};

type OtherGroupBonusStepProp = {
    onNext?: (answer: Partial<Answer>) => void,
    onBack?: () => void,
    question: QuestionToPlay,
    isSubmitting?: boolean,
}

export default function OtherGroupBonusStep({ question, onNext, onBack, isSubmitting }: OtherGroupBonusStepProp) {

    const { currentUserData } = useSignedInContext();
    const [groupState, setGroupState] = useState(GroupState);

    const groups = useMemo<GroupKey[]>(() => {
        if (question.identityGroupBonus?.bonusType === GroupBonuses.identity && currentUserData?.identity) {
            return Object
                .values(UserIdentity)
                .filter(identity => identity !== currentUserData?.identity);
        } else if (question.identityGroupBonus?.bonusType === GroupBonuses.ageGroup && currentUserData?.ageGroup) {
            return Object
                .values(AgeGroup)
                .filter(age => age !== currentUserData?.ageGroup);
        }
        return [];
    }, [currentUserData?.ageGroup, currentUserData?.identity, question.identityGroupBonus?.bonusType]);

    const itemSeparator = useCallback(() => <Spacer height={15} />, []);

    const imageUri = mbGetMediumImage(question.imageUrl, { asUri: true });

    const min = 0;
    const max = 100;
    const increment = question.answerOptions.increment || 1;

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>OTHER GROUP BONUS</Text>
                <Spacer />
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>200</Text>
                </ShadowView>
            </View>
            <View style={styles.topSection}>
                <Stepper style={styles.topSectionStepper} step={4} max={getNumberOfSteps(question)} />
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{question.identityGroupBonus?.question}</Text>
                    <Image resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} style={styles.topSectionImage} source={imageUri} />
                </View>
            </View>
            <FlatList
                style={styles.bottomSection}
                data={groups}
                contentContainerStyle={styles.bottomSectionContent}
                renderItem={({ item }) => (
                    <SliderCard
                        title={item}
                        value={groupState[item]}
                        onChange={value => setGroupState(prev => ({ ...prev, [item]: value }))}
                        steps={increment}
                        min={min}
                        max={max}
                        answerType={question.answerType}
                    />
                )}
                ItemSeparatorComponent={itemSeparator}
            />
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={styles.nextBtn}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={() => onNext?.({
                        identityGroupBonus: {
                            bisexual: groupState.bisexual,
                            lesbian: groupState.lesbian,
                            queer: groupState.queer,
                        },
                        ageGroupBonus: {
                            lateTeens: groupState.lateTeens,
                            '20s': groupState['20s'],
                            '30s': groupState['30s'],
                            '40s': groupState['40s'],
                            '50s+': groupState['50s+'],
                        },
                    })}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset(),
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 22,
        color: mbApplyTransparency('#FCAF41', 0.95),
    }]),
    topSection: {
        flex: 1,
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionImage: {
        width: '100%',
        flex: 1,
        borderRadius: 12,
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 21,
    }]),
    topSectionTitleBoldText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontWeight: '700',
        marginVertical: 14,
    }]),
    bottomSection: {
        flex: 1,
    },
    bottomSectionContent: {
        padding: 15,
    },
    sliderCard: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 30,
        paddingVertical: 10,
    },
    sliderCardText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '600',
        color: mbApplyTransparency('#000000', 0.95),
    }]),
    sliderCardValue: mbTextStyles([textStyles.largeText, {
        fontSize: 25,
        fontWeight: '700',
        color: mbApplyTransparency('#000000', 0.95),
        marginTop: -40,
        marginBottom: 5,
        zIndex: -1,
    }]),
    sliderCardBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
