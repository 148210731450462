import React, { useCallback, useState } from 'react';
import { trpc } from '../../../apiCalls/trpcClient';
import { ScreenProps } from '../../../typesAndInterfaces/componentProps';
import Results from './views/Results';
import PointsSummary from './views/PointsSummary';
import ResultStep from './views/ResultStep';
import { QuestionResultToView } from '@sapphicsavvy/business';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../navigations/RootNavigator';
import ScoreStep from './views/ScoreStep';
import CompareStep from './views/CompareStep';
import OtherGroupBonusStep from './views/OtherGroupBonusStep';
import IdealBonusStep from './views/IdealBonusStep';
import { getNumberOfSteps } from '../../../utils/shared';
import Summary from './views/Summary';

export type ActionType = 'standing' | 'synthesis' | 'support' | 'home';

export default function QuarterResults({ route }: ScreenProps<'QuarterResults'>) {

    const { quarterId, step, currentResultIndex = 0 } = route.params;

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const { data, isLoading } = trpc.quarter.getResults.useQuery({ quarterId });
    const [view, setView] = useState<'results' | 'pointsSummary' | 'summary'>('results');
    const totalPoints = (data?.pointsSummary?.quarterPoints || 0) + (data?.pointsSummary?.sleuthPoints || 0);
    const results = data?.results as QuestionResultToView[];
    const currentResults = results?.[currentResultIndex];

    const numberOfSteps = getNumberOfSteps(currentResults);

    const onNext = useCallback((actionType?: ActionType) => {
        if (view === 'summary') {
            if (actionType === 'home') {
                navigation.reset({
                    routes: [{ name: 'Tabs', params: { name: 'Home' } }],
                    index: 1,
                });
            }
        } else if (step === 'Result') {
            navigation.push('QuarterResults', { ...route.params, step: 'Score' });
        } else if (step === 'Score') {
            navigation.push('QuarterResults', { ...route.params, step: 'Compare' });
        } else if (step === 'Compare' && numberOfSteps > 3) {
            navigation.push('QuarterResults', { ...route.params, step: currentResults.identityGroupBonus ? 'OtherGroupBonus' : 'IdealBonusStep' });
        } else if (step === 'Compare' && numberOfSteps > 4) {
            navigation.push('QuarterResults', { ...route.params, step: 'IdealBonusStep' });
        } else if (step === 'Compare' || step === 'OtherGroupBonus' || step === 'IdealBonusStep') {
            const nextResultsIndex = currentResultIndex + 1;
            if (nextResultsIndex < results.length) {
                navigation.push('QuarterResults', { ...route.params, step: 'Result', currentResultIndex: nextResultsIndex });
            } else {
                setView('summary');
            }
        } else if (view === 'results') {
            setView('pointsSummary');
        } else if (view === 'pointsSummary') {
            navigation.push('QuarterResults', { ...route.params, step: 'Result', currentResultIndex: 0 });
        }
    }, [currentResultIndex, currentResults, navigation, numberOfSteps, results, route.params, step, view]);

    const onBack = useCallback(() => {
        navigation.goBack();
    }, [navigation]);


    if (view === 'summary') {
        return <Summary setNumber={data?.setNumber} quarterPoints={data?.pointsSummary?.quarterPoints} sleuthPoints={data?.pointsSummary?.sleuthPoints} quarterNumber={data?.quarterNumber} onPress={onNext} />;
    } else if (step === 'Result' && currentResults) {
        return <ResultStep points={totalPoints} results={currentResults} onBack={onBack} onNext={onNext} />;
    } else if (step === 'Score' && currentResults) {
        return <ScoreStep points={totalPoints} results={currentResults} onBack={onBack} onNext={onNext} />;
    } else if (step === 'Compare' && currentResults) {
        return <CompareStep points={totalPoints} results={currentResults} onBack={onBack} onNext={onNext} />;
    } else if (step === 'OtherGroupBonus' && currentResults) {
        return <OtherGroupBonusStep points={totalPoints} results={currentResults} onBack={onBack} onNext={onNext} />;
    } else if (step === 'IdealBonusStep' && currentResults) {
        return <IdealBonusStep points={totalPoints} results={currentResults} onBack={onBack} onNext={onNext} />;
    } else if (view === 'results') {
        return <Results isLoading={isLoading || !data} quarterPoints={data?.pointsSummary?.quarterPoints} sleuthPoints={data?.pointsSummary?.sleuthPoints} quarterNumber={data?.quarterNumber} onPress={onNext} />;
    } else if (view === 'pointsSummary') {
        return <PointsSummary quarterPoints={data?.pointsSummary?.quarterPoints} sleuthPoints={data?.pointsSummary?.sleuthPoints} quarterNumber={data?.quarterNumber} onPress={onNext} />;
    }

    return null;
}

