import React from 'react';
import { AnswerType, QuestionResultToView, ResultType } from '@sapphicsavvy/business';
import Layout from '../components/Layout';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import SliderResultCard from '../components/SliderResultCard';
import { Spacer } from '../../../helperComponents/Spacer';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { imageUtils } from '../../../../utils/imageUtils';
import { getNumberOfSteps } from '../../../../utils/shared';

type ResultStepProps = {
    onNext?: () => void
    onBack?: () => void,
    points: number,
    results: QuestionResultToView
}

export default function ResultStep({ points, onBack, onNext, results }: ResultStepProps) {

    const { currentUserData } = useSignedInContext();
    const imageUri = mbGetMediumImage(results.imageUrl, { asUri: true });

    return (
        <Layout
            title="RESULT"
            points={points}
            numberOfSteps={getNumberOfSteps(results)}
            currentStep={1}
            onBack={onBack}
            onNext={onNext}
            nextButtonColor="#0295CD"
            topSectionStyle={styles.topSection}
            titleColor="#0295CD"
            topSectionChildren={
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{results.guessQuestion}</Text>
                    <Image resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} style={styles.topSectionImage} source={imageUri} />
                </View>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <SliderResultCard
                title="Guess"
                type={results.answerType}
                value={results.userGuess}
                colors={results.answerType === AnswerType.percentage ? ['#EE3823', '#EE3823'] : ['#0295CD', '#0295CD']}
                image={
                    <View style={styles.imageConatiner}>
                        <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                    </View>
                }
                min={results.answerOptions.min}
                max={results.answerOptions.max}
            />
            <Spacer height={15} />
            <SliderResultCard
                title="Truth"
                type={results.answerType}
                value={results.surveyResult}
                colors={['#6A2C8C', '#6A2C8C']}
                image={
                    <View style={styles.logoContainer}>
                        <Image style={styles.image} source={imageUtils.screens.logoHomeScreen} resizeMode="contain" />
                    </View>
                }
                min={results.answerOptions.min}
                max={results.answerOptions.max}
            />
            {results.resultType === ResultType.outOfRange &&
                <View style={styles.wrongAnswer}>
                    <Text style={styles.answerText}>WRONG! YOU OVERESTIMATED</Text>
                </View>
            }
            {results.resultType === ResultType.exact &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>YOUR ANSWER IS CORRECT</Text>
                </View>
            }
        </Layout>
    );
}

const styles = StyleSheet.create({
    topSection: {
        backgroundColor: '#0295CD',
        height: '35%',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 20,
    }]),
    topSectionImage: {
        flex: 1,
        alignSelf: 'stretch',
        borderRadius: 12,
        marginHorizontal: 10,
    },
    bottomSection: {
        paddingHorizontal: 30,
        justifyContent: 'flex-start',
        paddingTop: 20,
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: '#FFFFFF',
    },
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: 'transparent',
    },
    image: {
        width: 60,
        height: 60,
        borderRadius: 80,
    },
    wrongAnswer: {
        backgroundColor: '#EE3823',
        position: 'absolute',
        left: 0,
        right: 0,
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
    },
    answerText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
    }]),
    correctAnswer: {
        backgroundColor: '#0295CD',
        position: 'absolute',
        left: 0,
        right: 0,
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
    },
});
