import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type IconProps = {
    size?: number,
    color?: string
}

export default function StatsIcon({ size = 40, color = '#F7D345' }: IconProps) {
  return (
    <Svg
      width={size}
      height={size * (34 / 40)}
      viewBox="0 0 40 34"
      fill="none"
    >
      <Path
        d="M40 34H3.122v-.006a3.138 3.138 0 01-2.207-.902 3.033 3.033 0 01-.908-2.165H0V0h3.99v30.07H40V34zM27.139 14.63h6.364c.338 0 .615.272.615.605v12.34a.612.612 0 01-.615.605h-6.364a.612.612 0 01-.616-.606V15.24c0-.334.277-.61.616-.61zM8.255 7.08h6.364c.339 0 .615.273.615.607v19.89a.612.612 0 01-.615.607H8.255a.612.612 0 01-.615-.607V7.687c0-.334.277-.607.615-.607zM17.7 2.833h6.36c.339 0 .615.272.615.606v24.138a.612.612 0 01-.615.607H17.7a.612.612 0 01-.616-.607V3.44c0-.334.277-.606.616-.606z"
        fill={color}
      />
    </Svg>
  );
}
