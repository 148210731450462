import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';

type LoadingContentProps = {
    style?: StyleProp<ViewStyle>
    height?: number | string
    width?: number | string
}

export default function LoadingContent(props: LoadingContentProps) {

    const flattenStyle = StyleSheet.flatten(props.style);
    const width = props.width ?? flattenStyle?.width;
    const height = props.height ?? flattenStyle?.height;

    return (
        <Placeholder
            Animation={Fade}
            style={[props.style, { width, height }]}
        >
            <PlaceholderLine style={{ width: '100%', height: '100%' }} />
        </Placeholder>
    );
}
