import React, { Fragment } from 'react';
import { AnswerType, QuestionResultToView } from '@sapphicsavvy/business';
import Layout from '../components/Layout';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { imageUtils } from '../../../../utils/imageUtils';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { getNumberOfSteps } from '../../../../utils/shared';

type CompareStepProps = {
    onNext?: () => void
    onBack?: () => void,
    points: number,
    results: QuestionResultToView
}

export default function CompareStep({ points, onBack, onNext, results }: CompareStepProps) {

    const { currentUserData } = useSignedInContext();

    const userAnswer = results.userAnswer;
    const surveyResult = results.surveyResult;
    const maxValue = Math.max(userAnswer, surveyResult);
    const userValue = userAnswer * 80 / maxValue;
    const surveyValue = surveyResult * 80 / maxValue;


    return (
        <Layout
            title="COMPARE"
            points={points}
            numberOfSteps={getNumberOfSteps(results)}
            currentStep={3}
            onBack={onBack}
            onNext={onNext}
            nextButtonColor="#F4602F"
            topSectionStyle={styles.topSection}
            titleColor="#F4602F"
            topSectionChildren={
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{results.sapphicQuestion}</Text>
                </View>
            }
            bottomSectionStyle={styles.bottomSection}
            enableBottomHighlight
        >
            {results.answerType === AnswerType.percentage ?
                <Fragment>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} source={imageUtils.screens.logoHomeScreen} resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} />
                    </View>
                    <View style={styles.statistics}>
                        <View style={[styles.statisticsLeftSection, { width: `${surveyResult}%` }]}>
                            <Text style={styles.statisticsLeftSectionText}>{surveyResult}%</Text>
                            <Text style={styles.statisticsLeftSectionTopText}>Yes</Text>
                        </View>
                        <View style={[styles.statisticsRightSection, { width: `${100 - surveyResult}%` }]}>
                            <Text style={styles.statisticsRightSectionText}>{100 - surveyResult}%</Text>
                            <Text style={styles.statisticsRightSectionTopText}>No</Text>
                        </View>
                    </View>
                    <View style={styles.userAnswer}>
                        <View style={styles.imageContainer}>
                            <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                        </View>
                        <Text style={styles.userAnswerText}>You said {results.userAnswer ? 'YES' : 'NO'}</Text>
                    </View>
                </Fragment>
                :
                <Fragment>
                    <ShadowView style={styles.statisticsNumber}>
                        <View style={styles.statisticsNumberSection}>
                            <Text style={[styles.valueText, { color: '#0295CD' }]}>{userAnswer}</Text>
                            <View style={[styles.statisticsBar, { backgroundColor: '#0295CD', height: `${userValue}%` }]} />
                            <View style={styles.statisticsNumberImageContainer}>
                                <Image style={styles.statisticsNumberImage} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                            </View>
                        </View>
                        <View style={styles.statisticsNumberSection}>
                            <Text style={[styles.valueText, { color: '#6A2C8C' }]}>{surveyResult}</Text>
                            <View style={[styles.statisticsBar, { backgroundColor: '#6A2C8C', height: `${surveyValue}%` }]} />
                            <View style={styles.statisticsNumberLogoContainer}>
                                <Image style={styles.statisticsNumberLogo} source={imageUtils.screens.logoHomeScreen} resizeMode="contain" />
                            </View>
                        </View>
                    </ShadowView>
                </Fragment>
            }
            {results.fact !== undefined &&
                <ShadowView style={styles.fact}>
                    <Text style={styles.factTitleText}>FACT</Text>
                    <Text style={styles.factText}>{results.fact}</Text>
                </ShadowView>
            }
        </Layout>
    );
}

const styles = StyleSheet.create({
    topSection: {
        backgroundColor: '#F4602F',
        height: '20%',
    },
    topSectionInfo: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        paddingHorizontal: 20,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
    }]),
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 80,
        alignSelf: 'center',
        backgroundColor: '#FFFFFF',
        height: 106,
        width: 106,
        marginTop: -45,
    },
    logo: {
        width: 71,
        height: 80,
    },
    bottomSection: {
        justifyContent: 'flex-start',
        paddingHorizontal: 20,
        paddingBottom: 20,
    },
    statistics: {
        height: 55,
        alignSelf: 'stretch',
        borderRadius: 22,
        flexDirection: 'row',
        marginTop: 50,
    },
    statisticsLeftSection: {
        backgroundColor: '#0295CD',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: 22,
        borderBottomLeftRadius: 22,
    },
    statisticsLeftSectionText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: '#FFFFFF',
    }]),
    statisticsLeftSectionTopText: mbTextStyles([textStyles.largeText, {
        fontSize: 17,
        fontWeight: '700',
        color: '#0295CD',
        position: 'absolute',
        top: -25,
    }]),
    statisticsRightSection: {
        backgroundColor: '#6A2C8C',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopRightRadius: 22,
        borderBottomRightRadius: 22,
    },
    statisticsRightSectionText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: '#FFFFFF',
    }]),
    statisticsRightSectionTopText: mbTextStyles([textStyles.largeText, {
        fontSize: 17,
        fontWeight: '700',
        color: '#6A2C8C',
        position: 'absolute',
        top: -25,
    }]),
    userAnswer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
        marginTop: 15,
    },
    userAnswerText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#000000',
    }]),
    imageContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        marginRight: 10,
    },
    image: {
        width: 55,
        height: 55,
        borderRadius: 80,
    },
    fact: {
        alignSelf: 'stretch',
        paddingHorizontal: 25,
        paddingVertical: 17,
        marginTop: 'auto',
    },
    factTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: '#F3522B',
    }]),
    factText: mbTextStyles([textStyles.largeText, {
        fontSize: 16,
        fontWeight: '500',
        color: mbApplyTransparency('#000000', 0.55),
        marginTop: 10,
    }]),
    statisticsNumber: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'stretch',
        marginVertical: 15,
        paddingTop: 25,
        paddingBottom: 80,
        paddingHorizontal: 40,
        maxHeight: 400,
    },
    statisticsNumberSection: {
        flex: 1,
        alignItems: 'center',
    },
    valueText: mbTextStyles([textStyles.largeText, {
        fontSize: 35,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        marginTop: 'auto',
    }]),
    statisticsBar: {
        width: 41,
        borderRadius: 5,
        marginTop: 10,
    },
    statisticsNumberImageContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        position: 'absolute',
        bottom: -60,
    },
    statisticsNumberImage: {
        width: 75,
        height: 75,
        borderRadius: 80,
    },
    statisticsNumberLogoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 80,
        alignSelf: 'center',
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: '#FFFFFF',
        width: 75,
        height: 75,
        position: 'absolute',
        bottom: -60,
    },
    statisticsNumberLogo: {
        width: 49,
        height: 56,
    },
});
