export enum AnswerType {
    percentage = 'percentage',
    numeric = 'numeric',
}
export enum GroupBonuses {
    ageGroup = 'ageGroup',
    identity = 'identity',
    none = 'none',
}

export enum QuarterStatus {
    draft = 'draft',
    published = 'published',
    active = 'active',
    closed = 'closed',
}
export enum QuarterResultsStatus {
    standBy = 'standBy',
    readyToCalc = 'readyToCalc',
    calcInProgress = 'calcInProgress',
    calcCompleted = 'calcCompleted',
    calcFailed = 'calcFailed',
}

export enum ResultType {
    exact = 'exact', // exact match
    inWiggle = 'inWiggle', // within the wiggle room
    inMargin = 'inMargin', // within the margin of error
    outOfRange = 'outOfRange', // out of range
}

export enum PointEvents {
    initialAccountSetup = 'initialAccountSetup',
    startOfQuarter = 'startOfQuarter',
    usedReferralCode = 'usedReferralCode',
    questionAnswered = 'questionAnswered',
    betResult = 'betResult',
    idealBonus = 'idealBonus',
    identityGroupBonus = 'identityGroupBonus',
    ageGroupBonus = 'ageGroupBonus',
}
export enum TopScoresType {
    lastQuarter = 'lastQuarter',
    currentSet = 'currentSet',
}
