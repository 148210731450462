import { QuestionToPlay } from '@sapphicsavvy/business';
import React, { useCallback, useState } from 'react';
import { Platform, View, ViewProps } from 'react-native';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import StarIcon from '../resources/svgComponents/StarIcon';

export function createReactComponent<P>(Component: React.ComponentType<P>) {
    class ReactComponent extends React.Component<P> {
        render(): React.ReactNode {
            return <Component {...this.props} />;
        }
    }
    return ReactComponent;
}

export const hitSlop = { top: 10, bottom: 10, left: 10, right: 10 };

export function useModalState<T>(initialState?: T) {
    const [state, setState] = useState(initialState);

    return {
        state,
        show: useCallback((updateState: T) => setState(updateState), []),
        hide: useCallback(() => setState(undefined), []),
    };
}

export function getTopInset() {
    return Platform.OS === 'ios' ? (initialWindowMetrics?.insets.top || 0) : 0;
}


export function ShouldSetResponderWrapper(props: ViewProps) {
    // if (Platform.OS === 'android') {
    //     return (
    //         <TouchableOpacity {...props} activeOpacity={1} />
    //     );
    // }
    return (
        <View {...props} onStartShouldSetResponder={() => true} />
    );
}

export default function renderRandomStars(points: [number | string, number | string, number][]) {
    return (
        <React.Fragment>
            {points.map(point => (
                <View key={JSON.stringify(point)} style={{ position: 'absolute', top: point[1], left: point[0] }}>
                    <StarIcon size={point[2]} />
                </View>
            ))}
        </React.Fragment>
    );
}

export function getNumberOfSteps(question: QuestionToPlay) {
    return 3 + (question?.identityGroupBonus ? 1 : 0) + (question?.hasIdealBonus ? 1 : 0);
}

export function NotNull<T>(value?: T | undefined | null) {
    return value !== undefined && value !== null;
}

export function capitalize(s: string) {
    return s[0].toUpperCase() + s.slice(1);
}
