import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CorrectAnswerIcon() {
  return (
    <Svg
      width={26}
      height={19}
      viewBox="0 0 26 19"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.255 9.081l4.615-.048c1.783.822 3.378 1.886 4.752 3.233C13.175 7.553 17.257 3.69 21.744.473h3.517C18.987 6.049 13.867 12.018 9.772 18.34c-2.19-3.755-5.252-6.917-9.517-9.26z"
        fill="#01A601"
      />
    </Svg>
  );
}
