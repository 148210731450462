import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

type PlayIconProps = {
    size?: number,
    color?: string
}

export default function PlayIcon({ size = 36, color = '#0295CD' }: PlayIconProps) {
    return (
        <Svg
            width={size}
            height={size * (37 / 36)}
            viewBox="0 0 36 37"
            fill="none"
        >
            <G clipPath="url(#clip0_289_45540)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 .69c9.94 0 18 8.059 18 18 0 9.94-8.06 18-18 18s-18-8.06-18-18c0-9.941 8.06-18 18-18zm6.876 19.195c.999-.645.999-1.366 0-1.937L14.54 12.007c-.814-.513-1.667-.211-1.643.855l.032 12.006c.07 1.155.73 1.47 1.699.935l10.248-5.918z"
                    fill={color}
                />
            </G>
            <Defs>
                <ClipPath id="clip0_289_45540">
                    <Path fill="#fff" transform="translate(0 .69)" d="M0 0H36V36H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
