import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ShadowView } from './ShadowView';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../constants/textStyles';

type CardProps = {
    title: string,
    points: number,
    backgroundColor: string,
    textColor: string,
    disableBorder?: boolean,
    disableShadow?: boolean,
}

export default function PointsCard({ backgroundColor, textColor, points, title, disableBorder, disableShadow }: CardProps) {
    const Container = disableShadow ? View : ShadowView;
    return (
        <Container style={[styles.card, { backgroundColor, borderColor: disableBorder ? 'transparent' : styles.card.borderColor }]}>
            <Text style={[styles.cardTitleText, { color: textColor }]}>{title}</Text>
            <Text style={[styles.cardPointsText, { color: textColor }]}>{points}</Text>
        </Container>
    );
}

const styles = StyleSheet.create({
    card: {
        borderWidth: 1,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 30,
        paddingVertical: 15,
        marginBottom: 15,
    },
    cardTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        textAlign: 'left',
        fontWeight: '700',
    }]),
    cardPointsText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 22,
        fontWeight: '400',
    }]),
});
