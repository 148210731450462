import React from 'react';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import PlayIcon from '../../../../resources/svgComponents/PlayIcon';
import ReplayIcon from '../../../../resources/svgComponents/ReplayIcon';

type ResultCardProps = {
    title: string
    active?: boolean
    isNew?: boolean
    onPress?: () => void
}

export default function ResultCard({ title, active, isNew, onPress }: ResultCardProps) {
    return (
        <ShadowView style={[styles.card, isNew && styles.cardNew]} onPress={active ? onPress : undefined}>
            <Text style={styles.titleText}>{title}</Text>
            {isNew &&
                <View style={styles.new}>
                    <Text style={styles.newText}>NEW</Text>
                </View>
            }
            {isNew ? <PlayIcon /> : <ReplayIcon />}
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    card: {
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 21,
        paddingVertical: 20,
    },
    cardNew: {
        borderWidth: 2,
        borderColor: '#0295CD',
    },
    titleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 25,
        textAlign: 'left',
        color: '#0295CD',
    }]),
    new: {
        backgroundColor: '#0295CD',
        borderRadius: 12,
        paddingHorizontal: 10,
        paddingVertical: 2,
        position: 'absolute',
        top: -10,
        left: 15,
    },
    newText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 14,
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
});
