import { MB_Button } from '@mightybyte/rnw.components.button';
import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import Stepper from '../../../helperComponents/Stepper';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { getTopInset } from '../../../../utils/shared';

type LayoutProps = {
    onNext?: () => void,
    onBack?: () => void,
    points: number,
    numberOfSteps: number,
    currentStep: number,
    title: string,
    titleColor?: string,
    titleStyle?: StyleProp<TextStyle>
    headerColor?: string,
    topSectionStyle?: StyleProp<ViewStyle>
    topSectionChildren?: React.ReactChild,
    children?: React.ReactNode,
    bottomSectionStyle?: StyleProp<ViewStyle>,
    nextButtonColor?: string
    enableBottomHighlight?: boolean,
    pointsColor?: string
    pointsShadowColors?: string,
    stepperBackgroundColor?: string,
}

export default function Layout({ onBack, onNext, points, numberOfSteps, currentStep, title, titleColor, titleStyle, headerColor, topSectionStyle, topSectionChildren, children, bottomSectionStyle, nextButtonColor, enableBottomHighlight, pointsColor = '#F35E2E', pointsShadowColors, stepperBackgroundColor }: LayoutProps) {

    return (
        <View style={styles.container}>
            <View style={[styles.header, { backgroundColor: headerColor }]}>
                <Text style={[styles.headerTitleText, titleStyle, { color: titleColor }]}>{title}</Text>
                <Spacer />
                <ShadowView style={[styles.coins, headerColor !== undefined && { backgroundColor: headerColor }]} topLeftShadowColor={pointsShadowColors} bottomRightShadowColor={pointsShadowColors}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={[styles.pointsText, { color: pointsColor }]}>{points}</Text>
                </ShadowView>
            </View>
            <View style={[styles.topSection, topSectionStyle]}>
                <View style={{ backgroundColor: stepperBackgroundColor }}>
                    <Stepper style={styles.topSectionStepper} step={currentStep} max={numberOfSteps} />
                </View>
                {topSectionChildren}
                {enableBottomHighlight && <View style={styles.topSectionBottom} />}
            </View>
            <View style={[styles.bottomSection, bottomSectionStyle]}>
                {children}
            </View>
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={[styles.nextBtn, { backgroundColor: nextButtonColor }]}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={onNext}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        paddingTop: getTopInset(),
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 38,
        color: '#F4602F',
    }]),
    topSection: {
        height: '50%',
        backgroundColor: '#F4602F',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingBottom: 80,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionBottom: {
        height: 20,
        marginTop: 'auto',
        backgroundColor: mbApplyTransparency('#000000', 0.12),
    },
    bottomSection: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: '#F4602F',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
