import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, {
    Path,
    Rect,
    Defs,
    LinearGradient,
    Stop,
    Mask,
    G,
} from 'react-native-svg';

type IconProps = {
    style?: StyleProp<ViewStyle>
}

export default function WinIcon({ style }: IconProps) {
    return (
        <Svg
            width={193}
            height={276}
            viewBox="0 0 193 276"
            fill="none"
            style={style}
        >
            <Path
                d="M192.791 90.049H.5v185.67h192.291V90.049z"
                fill="url(#paint0_linear_291_46614)"
                opacity={0.5}
            />
            <Rect
                x={12.9059}
                y={12.4059}
                width={167.417}
                height={167.417}
                rx={83.7083}
                fill="#F9C752"
                stroke="#ECA031"
                strokeWidth={24.8117}
            />
            <Mask
                id="a"
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={193}
                height={193}
            >
                <Rect
                    x={0.5}
                    width={192.228}
                    height={192.228}
                    rx={96.1142}
                    fill="url(#pattern0_291_46614)"
                />
            </Mask>
            <G mask="url(#a)" fill="#fff" fillOpacity={0.2}>
                <Path
                    transform="rotate(-29.381 82.326 -74.719)"
                    d="M82.3262 -74.7188H107.007V196.84320000000002H82.3262z"
                />
                <Path
                    transform="rotate(-29.381 -17.742 -21.227)"
                    d="M-17.7422 -21.2266H6.938600000000001V250.33540000000002H-17.7422z"
                />
                <Path
                    transform="rotate(-29.381 7.377 -38.672)"
                    d="M7.37695 -38.6719H14.295269999999999V232.89010000000002H7.37695z"
                />
            </G>
            <Rect
                x={18.4141}
                y={18.5586}
                width={156.402}
                height={155.02}
                rx={77.5099}
                stroke="#ECA031"
                strokeWidth={15}
            />
            <Rect
                x={12.9059}
                y={12.4059}
                width={167.417}
                height={167.417}
                rx={83.7083}
                stroke="#ECA031"
                strokeWidth={24.8117}
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_291_46614"
                    x1={96.6455}
                    y1={117.962}
                    x2={96.6455}
                    y2={257.528}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#BF7711" />
                    <Stop offset={1} stopColor="#ECA031" stopOpacity={0} />
                </LinearGradient>
            </Defs>
        </Svg>
    );
}

