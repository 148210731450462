import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function WrongAnswerIcon() {
    return (
        <Svg
            width={23}
            height={22}
            viewBox="0 0 23 22"
            fill="none"
        >
            <Path
                d="M4.237 6.071c5.755 4.44 9.96 9.113 14.205 15.032 2.233-1.942 2.79-2.596 1.058-5.386C16.932 11.58 9.83 5.64 5.648 4.403c-.5-.148-.96-.222-1.35-.213-.23.007-.539.185-.747.419-.466.523.035.96.686 1.462z"
                fill="red"
            />
            <Path
                d="M20.345.778C14.3 7.451 7.947 13.957.049 19.972c2.863 2.036 3.953 1.946 6.88-.072 5.023-3.465 12.447-11.767 15.027-16.454.43-.782.873-2.052-.235-2.557-.45-.205-1.044-.305-1.376-.11z"
                fill="red"
            />
        </Svg>
    );
}
