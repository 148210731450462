import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, {
    Path,
    Rect,
    Mask,
    G,
    Circle,
    Defs,
    LinearGradient,
    Stop,
} from 'react-native-svg';

type IconProps = {
    style?: StyleProp<ViewStyle>
}

export default function NoWinIcon({ style }: IconProps) {
    return (
        <Svg
            width={194}
            height={263}
            viewBox="0 0 194 263"
            fill="none"
            style={style}
        >
            <Path
                d="M193.412 90.607H.244v171.874h193.168V90.607z"
                fill="url(#paint0_linear_289_46411)"
                opacity={0.5}
            />
            <Rect
                x={12.649}
                y={12.5543}
                width={168.293}
                height={168.293}
                rx={84.1465}
                fill="#F9C752"
                stroke="#ECA031"
                strokeWidth={24.8117}
            />
            <Mask
                id="a"
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={194}
                height={194}
            >
                <Rect
                    x={0.243164}
                    y={0.148438}
                    width={193.105}
                    height={193.105}
                    rx={96.5524}
                    fill="#000"
                />
            </Mask>
            <G mask="url(#a)">
                <Path
                    transform="rotate(-29.381 -12.018 .053)"
                    fill="#fff"
                    fillOpacity={0.2}
                    d="M-12.0176 0.0527344H12.775699999999999V272.8517344H-12.0176z"
                />
            </G>
            <Rect
                x={18.2041}
                y={18.7578}
                width={157.183}
                height={155.795}
                rx={77.8975}
                stroke="#ECA031"
                strokeWidth={15}
            />
            <Rect
                x={12.649}
                y={12.5543}
                width={168.293}
                height={168.293}
                rx={84.1465}
                stroke="#ECA031"
                strokeWidth={24.8117}
            />
            <Circle cx={96.415} cy={96.1963} r={71.4385} fill="#EE3823" />
            <Mask id="b" fill="#fff">
                <Path d="M192.787 96.298c0 53.187-43.117 96.304-96.304 96.304-53.188 0-96.304-43.117-96.304-96.304C.179 43.111 43.295-.006 96.483-.006c53.187 0 96.304 43.117 96.304 96.304zm-170.181 0c0 40.801 33.076 73.877 73.877 73.877 40.801 0 73.877-33.076 73.877-73.877 0-40.8-33.076-73.877-73.877-73.877-40.801 0-73.877 33.076-73.877 73.877z" />
            </Mask>
            <Path
                d="M192.787 96.298c0 53.187-43.117 96.304-96.304 96.304-53.188 0-96.304-43.117-96.304-96.304C.179 43.111 43.295-.006 96.483-.006c53.187 0 96.304 43.117 96.304 96.304zm-170.181 0c0 40.801 33.076 73.877 73.877 73.877 40.801 0 73.877-33.076 73.877-73.877 0-40.8-33.076-73.877-73.877-73.877-40.801 0-73.877 33.076-73.877 73.877z"
                fill="#EE3823"
                stroke="#FCAF41"
                strokeOpacity={0.956863}
                strokeWidth={4}
                mask="url(#b)"
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_289_46411"
                    x1={96.8279}
                    y1={116.447}
                    x2={96.5518}
                    y2={287.157}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A62111" />
                    <Stop offset={1} stopColor="#ECA031" stopOpacity={0} />
                </LinearGradient>
            </Defs>
        </Svg>
    );
}
