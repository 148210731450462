import React, { useCallback, useRef, useState } from 'react';
import { ScreenProps } from '../../../typesAndInterfaces/componentProps';
import Begin from './components/Begin';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Answer, RootStackParamList } from '../../../navigations/RootNavigator';
import GuessStep from './components/GuessStep';
import BetStep from './components/BetStep';
import AnswerStep from './components/AnswerStep';
import OtherGroupBonusStep from './components/OtherGroupBonusStep';
import IdealBonusStep from './components/IdealBonusStep';
import { getNumberOfSteps } from '../../../utils/shared';
import { trpc } from '../../../apiCalls/trpcClient';
import { utils } from '../../../utils/utils';
import { STRING_CONSTANTS } from '../../../constants/constants';
import Complete from './components/Complete';
import { AddQuarterAnswerResponse } from '@sapphicsavvy/business';

export default function Quarter({ route }: ScreenProps<'Quarter'>) {
    const { quarterData, questionIndex, step, answer } = route.params;

    const [isBeginVisible, setIsBeginVisible] = useState(questionIndex === 0 && step === 'Guess');
    const [isCompleteVisible, setIsCompleteVisible] = useState(false);

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const question = quarterData.questions[questionIndex];
    const trpcUtils = trpc.useUtils();
    const pointsRef = useRef<AddQuarterAnswerResponse>();
    const { mutate: addQuarterAnswer, isLoading: isAddingAnswer } = trpc.quarter.addQuarterAnswer.useMutation();

    if (!question) {
        throw new Error('no question found.');
    }

    const numberOfSteps = getNumberOfSteps(question);

    const onNext = useCallback((partialAnswer: Partial<Answer>) => {
        const newAnswer = { ...answer, ...partialAnswer };
        if (isBeginVisible) {
            setIsBeginVisible(false);
        } else if (isCompleteVisible) {
            navigation.reset({
                routes: [{ name: 'Tabs', params: { name: 'Home' } }],
                index: 1,
            });
        } else if (step === 'Guess') {
            navigation.push('Quarter', { ...route.params, step: 'Bet', answer: newAnswer });
        } else if (step === 'Bet') {
            navigation.push('Quarter', { ...route.params, step: 'Answer', answer: newAnswer });
        } else if (step === 'Answer' && numberOfSteps > 3) {
            navigation.push('Quarter', { ...route.params, step: question.identityGroupBonus ? 'OtherGroupBonus' : 'IdealBonusStep', answer: newAnswer });
        } else if (step === 'OtherGroupBonus' && numberOfSteps > 4) {
            navigation.push('Quarter', { ...route.params, step: 'IdealBonusStep', answer: newAnswer });
        } else {
            const nextQuestionIndex = questionIndex + 1;
            const answers = [...route.params.answers, newAnswer];
            if (nextQuestionIndex < quarterData.questions.length) {
                navigation.push('Quarter', {
                    ...route.params,
                    questionIndex: nextQuestionIndex,
                    step: 'Guess',
                    answer: {
                        questionId: quarterData.questions[nextQuestionIndex].id,
                        answerType: quarterData.questions[nextQuestionIndex].answerType,
                    },
                    answers,
                });
            } else {
                addQuarterAnswer({
                    quarterId: quarterData.id,
                    data: answers.map(e => ({
                        questionId: e.questionId,
                        answerType: e.answerType,
                        guess: e.guess,
                        extraBet: e.extraBet,
                        answer: e.answer,
                        identityGroupBonus: e.identityGroupBonus,
                        ageGroupBonus: e.ageGroupBonus,
                        idealBonus: e.idealBonus,
                    })),
                }, {
                    onSuccess: (data) => {
                        trpcUtils.quarter.haveToPlay.invalidate();
                        pointsRef.current = { ...data };
                        setIsCompleteVisible(true);
                    },
                    onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
                });
            }
        }
    }, [addQuarterAnswer, answer, isBeginVisible, isCompleteVisible, navigation, numberOfSteps, quarterData.id, quarterData.questions, question.identityGroupBonus, questionIndex, route.params, step, trpcUtils.quarter.haveToPlay]);

    const onBack = useCallback(() => {
        navigation.goBack();
    }, [navigation]);

    if (isBeginVisible) {
        return <Begin onPress={() => onNext({})} />;
    } else if (isCompleteVisible) {
        if (!pointsRef.current) {
            throw new Error('[Quarter] points is missing');
        }
        return <Complete quarterData={quarterData} points={pointsRef.current} onPress={() => onNext({})} />;
    } else if (step === 'Guess') {
        return <GuessStep question={question} onNext={onNext} onBack={onBack} />;
    } else if (step === 'Bet') {
        return <BetStep question={question} answer={answer} onNext={onNext} onBack={onBack} />;
    } else if (step === 'Answer') {
        return <AnswerStep question={question} onNext={onNext} onBack={onBack} isSubmitting={isAddingAnswer} />;
    } else if (step === 'OtherGroupBonus') {
        return <OtherGroupBonusStep question={question} onNext={onNext} onBack={onBack} isSubmitting={isAddingAnswer} />;
    } else if (step === 'IdealBonusStep') {
        return <IdealBonusStep question={question} onNext={onNext} onBack={onBack} isSubmitting={isAddingAnswer} />;
    }

    return null;
}

