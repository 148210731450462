import React, { Fragment } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import OveviewScreen from '../../../helperComponents/OveviewScreen';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import { StandingWithNumberIcon } from '../../../../resources/svgComponents/StandingWithNumberIcon';
import StandingIcon from '../../../../resources/svgComponents/StandingIcon';
import SynthesisIcon from '../../../../resources/svgComponents/SynthesisIcon';
import SupportIcon from '../../../../resources/svgComponents/SupportIcon';
import { ActionType } from '../QuarterResults';

type CardProps = {
    title: string,
    value: string | number,
    icon: React.ReactChild
}

function Card({ title, value, icon }: CardProps) {
    return (
        <ShadowView style={styles.card}>
            <Text style={styles.cardTitleText}>{title}</Text>
            <View style={styles.cardContent}>
                {icon}
                <View style={styles.cardValueContainer}>
                    <Text adjustsFontSizeToFit numberOfLines={1} style={styles.cardValueText}>{value}</Text>
                </View>
            </View>
        </ShadowView>
    );
}

type ButtonProps = {
    title: string,
    icon: React.ReactChild,
    color: string,
    backgroundColor: string
}

function Button({ title, icon, color, backgroundColor }: ButtonProps) {
    return (
        <ShadowView style={[styles.button, { backgroundColor }]}>
            {icon}
            <Text style={[styles.buttonTitleText, { color }]}>{title}</Text>
        </ShadowView>
    );
}

type SummaryProps = {
    setNumber?: number,
    quarterNumber?: number,
    quarterPoints?: number,
    sleuthPoints?: number
    onPress?: (type: ActionType) => void,
}

export default function Summary({ setNumber, quarterNumber, quarterPoints, sleuthPoints, onPress }: SummaryProps) {

    const totalPoints = (quarterPoints || 0) + (sleuthPoints || 0);

    return (
        <OveviewScreen
            topSectionStyle={styles.topSection}
            gardientColors={['#EF3A24', '#F9943B']}
            topSectionChildren={
                <Fragment>
                    <Text style={styles.topTitleText}>SET {setNumber}</Text>
                    <Text style={styles.titleText}>QUARTER {quarterNumber}</Text>
                    <Text style={styles.topTitleText}>Summary</Text>
                </Fragment>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <View style={styles.content}>
                <View style={styles.cards}>
                    <Card
                        title="Score"
                        value={totalPoints}
                        icon={<CoinsIcon size={36} />}
                    />
                    <Spacer width={15} />
                    <Card
                        title="Rank"
                        value={1}
                        icon={<StandingWithNumberIcon size={45} />}
                    />
                </View>
                <View style={styles.spacer} />
                <View style={styles.action}>
                    <Button
                        title="Standings"
                        icon={<StandingIcon size={18} />}
                        backgroundColor="#FDE4DC"
                        color="#F35E2E"
                    />
                    <Spacer height={18} />
                    <Button
                        title="Synthesis"
                        icon={<SynthesisIcon />}
                        backgroundColor="#F9EFFF"
                        color="#6A2C8C"
                    />
                    <Spacer height={18} />
                    <Button
                        title="Support"
                        icon={<SupportIcon />}
                        backgroundColor="#EBF9FF"
                        color="#0295CD"
                    />
                    <Spacer height={18} />
                    <GradientButton
                        title="BACK TO HOME"
                        containerStyle={styles.btn}
                        onPress={() => onPress?.('home')}
                    />
                </View>
            </View>
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
        height: '40%',
    },
    topTitleText: mbTextStyles([textStyles.largestText, {
        fontSize: 18,
        fontWeight: '700',
        color: '#FFFFFF',
    }]),
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        marginVertical: 9,
    }]),
    bottomSection: {
        height: '60%',
    },
    content: {
        paddingHorizontal: 25,
        paddingBottom: 20,
        paddingTop: 10,
        flex: 1,
    },
    cards: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        marginBottom: 15,
    },
    spacer: {
        backgroundColor: '#E8E4E4',
        height: 2,
        marginHorizontal: -40,
    },
    action: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn: {
        width: 227,
    },

    // Card
    card: {
        flexGrow: 1,
        paddingHorizontal: 20,
        paddingVertical: 15,
    },
    cardContent: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        alignItems: 'center',
        marginTop: 10,
    },
    cardTitleText: mbTextStyles([textStyles.largerText, {
        fontSize: 18,
        color: '#F35E2E',
        textAlign: 'left',
    }]),
    cardValueContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardValueText: mbTextStyles([textStyles.largerText, {
        fontSize: 35,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
        marginLeft: 8,
        textAlign: 'left',
    }]),

    // Button
    button: {
        borderWidth: 1,
        borderColor: '#FFFFFF8C',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 227,
        paddingVertical: 10,
    },
    buttonTitleText: mbTextStyles([textStyles.largestText, {
        fontSize: 18,
        marginLeft: 18,
    }]),
});
