import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SupportIcon() {
  return (
    <Svg
      width={25}
      height={21}
      viewBox="0 0 25 21"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.647 18.17v-7.38h3.26c1.38.252 2.762 1.015 4.143 1.9h2.532c1.146.07 1.746 1.254.632 2.032-.887.663-2.059.625-3.26.515-.828-.042-.863 1.091 0 1.095.3.024.626-.047.91-.047 1.5-.003 2.732-.294 3.488-1.5l.379-.901 3.765-1.9c1.884-.633 3.223 1.374 1.835 2.771a49.145 49.145 0 01-8.385 5.027c-2.078 1.288-4.156 1.244-6.232 0L6.647 18.17zm9.075-16.34c.713-.758 1.211-1.412 2.31-1.54 2.06-.242 3.956 1.909 2.916 4.026-.296.603-.899 1.319-1.566 2.023-.732.771-1.542 1.529-2.111 2.102l-1.549 1.565-1.279-1.255c-1.538-1.509-4.048-3.41-4.13-5.764C10.252 1.337 11.532.28 13 .3c1.315.016 1.866.682 2.722 1.53zM.793 10.08h4.872v8.831H.793v-8.83z"
        fill="#0295CD"
      />
    </Svg>
  );
}
