import React, { Fragment, useCallback } from 'react';
import { trpc } from '../../../apiCalls/trpcClient';
import { FlatList, Image, ListRenderItemInfo, StyleSheet, Text, View } from 'react-native';
import OveviewScreen from '../../helperComponents/OveviewScreen';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { useSignedInContext } from '../../../context/SignedInContext';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import { QuarterResultsStatus } from '@sapphicsavvy/business';
import ResultCard from './components/ResultCard';
import { Spacer } from '../../helperComponents/Spacer';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../navigations/RootNavigator';
import { useNavigation } from '@react-navigation/core';

type Result = {
    id: string,
    quarterId: string
    quarterNumber: number,
    setNumber: number,
    status: QuarterResultsStatus,
    resultsViewCount?: number,
}

export default function ScoreAndResults() {
    const { currentUserData } = useSignedInContext();
    const { data, isLoading } = trpc.quarter.getList.useQuery();
    const { mutate: countView } = trpc.quarter.countView.useMutation();

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const onViewResult = useCallback((item: Result) => {
        countView({ resultId: item.id });
        navigation.navigate('QuarterResults', { quarterId: item.quarterId });
    }, [countView, navigation]);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<Result>) => {
        return (
            <ResultCard
                title={`SET ${item.setNumber} QUARTER ${item.quarterNumber}`}
                onPress={() => onViewResult(item)}
                active={item.status === QuarterResultsStatus.calcCompleted}
                isNew={(item.resultsViewCount || 0) === 0}
            />
        );
    }, [onViewResult]);

    const itemSeparator = useCallback(() => <Spacer height={15} />, []);

    const EmptyComponent = useCallback(() => {
        if (isLoading) {
            return (
                <View>
                    <UIActivityIndicator size={30} color="#0295CD" />
                </View>
            );
        }
        return (
            <View>
                <Text style={styles.emptyText}>No result available.</Text>
            </View>
        );
    }, [isLoading]);

    return (
        <OveviewScreen
            gardientColors={['#0295CD', '#0295CD']}
            showBackBtn
            topSectionStyle={styles.topSection}
            topSectionChildren={
                <Fragment>
                    <Text style={styles.titleText}>SCORES & RESULTS</Text>
                    <View style={styles.imageConatiner}>
                        <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                    </View>
                </Fragment>
            }
        >
            <FlatList
                data={data ?? []}
                renderItem={renderItem}
                contentContainerStyle={styles.listContent}
                ItemSeparatorComponent={itemSeparator}
                ListEmptyComponent={EmptyComponent}
            />
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        paddingHorizontal: 20,
    }]),
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        marginTop: 30,
    },
    image: {
        width: 143,
        height: 143,
        borderRadius: 80,
    },
    listContent: {
        paddingHorizontal: 30,
        paddingTop: 20,
        paddingBottom: 20,
    },
    emptyText: mbTextStyles([textStyles.largeText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#0295CD',
        fontWeight: '400',
    }]),
});
