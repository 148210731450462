import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { ShadowView } from './ShadowView';
import StarIcon from '../../resources/svgComponents/StarIcon';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../constants/textStyles';

type HighlightCardProps = {
    text: string | number,
    textStyle?: StyleProp<TextStyle>,
    style?: StyleProp<ViewStyle>
}

export default function HighlightCard({ text, textStyle, style }: HighlightCardProps) {
    return (
        <ShadowView style={[styles.container, style]}>
            <Text style={[styles.textText, textStyle]}>{text}</Text>
            <View style={styles.topStar}>
                <StarIcon size={57} color={mbApplyTransparency('#F88337', 0.08)} />
            </View>
            <View style={styles.bottomStar}>
                <StarIcon size={38} color={mbApplyTransparency('#F88337', 0.08)} />
            </View>
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        paddingVertical: 20,
    },
    textText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 25,
        color: '#EF3A24',
    }]),
    topStar: {
        position: 'absolute',
        top: 0,
        left: 20,
        justifyContent: 'center',
    },
    bottomStar: {
        position: 'absolute',
        top: 0,
        right: 50,
    },
});
