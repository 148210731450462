import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import GradientContainer from './gradients/GradientContainer';
import React from 'react';
import { mbShadow } from '@mightybyte/rnw.utils.style-utils';
import renderRandomStars, { getTopInset, hitSlop } from '../../utils/shared';
import ArrowLeft from '../../resources/svgComponents/ArrowLeft';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../navigations/RootNavigator';

const stars: [number | string, number | string, number][] = [
    ['27%', '4%', 19],
    ['6%', '36%', 35],
    ['9%', '62%', 19],
    ['13%', '92%', 19],
    ['23%', '55%', 57],
    ['50%', '80%', 19],
    ['94%', '4%', 35],
    ['90%', '14%', 19],
    ['76%', '36%', 19],
    ['85%', '62%', 19],
    ['90%', '86%', 35],
];

type OveviewScreenProps = {
    topSectionStyle?: StyleProp<ViewStyle>,
    topSectionChildren?: React.ReactChild,
    bottomSectionStyle?: StyleProp<ViewStyle>,
    children?: React.ReactNode,
    gardientColors?: [string, string],
    showBackBtn?: boolean,
}

export default function OveviewScreen({ topSectionStyle, topSectionChildren, bottomSectionStyle, children, gardientColors, showBackBtn }: OveviewScreenProps) {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    return (
        <GradientContainer style={styles.container} angle={-90} colors={gardientColors}>
            <View style={[styles.topSection, topSectionStyle]}>
                {renderRandomStars(stars)}
                {topSectionChildren}
                {showBackBtn &&
                    <TouchableOpacity
                        hitSlop={hitSlop}
                        style={styles.backBtn}
                        onPress={navigation.goBack}
                    >
                        <ArrowLeft />
                    </TouchableOpacity>
                }
            </View>
            <View style={[styles.bottomSection, bottomSectionStyle]}>
                {children}
            </View>
        </GradientContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    topSection: {
        height: '50%',
        paddingTop: getTopInset(),
    },
    bottomSection: {
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        backgroundColor: '#EEEEF2',
        paddingTop: 30,
        height: '50%',
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.15,
            radius: 10,
            elevation: 2,
        }),
    },
    backBtn: {
        position: 'absolute',
        left: 20,
        top: getTopInset(),
    },
});
