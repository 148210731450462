import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type IconProps = {
    size?: number,
    color?: string,
}

export function ArrowRight({ size = 9, color = '#C3C9CD' }: IconProps) {
    return (
        <Svg
            width={size}
            height={size * (20 / 9)}
            viewBox="0 0 9 20"
            fill="none"
        >
            <Path
                d="M1.63 1.251l6.323 8.485-6.324 8.485"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}
