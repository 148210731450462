import React, { useCallback, useMemo } from 'react';
import { QuestionResultToView } from '@sapphicsavvy/business';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import Layout from '../components/Layout';
import { FlatList, Image, Platform, StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { imageUtils } from '../../../../utils/imageUtils';
import InfoCard from '../components/InfoCard';
import { getNumberOfSteps } from '../../../../utils/shared';

type OtherGroupBonusStepProps = {
    onNext?: () => void
    onBack?: () => void,
    points: number,
    results: QuestionResultToView
}

export default function OtherGroupBonusStep({ points, onBack, onNext, results }: OtherGroupBonusStepProps) {

    const { currentUserData } = useSignedInContext();
    const imageUri = mbGetMediumImage(results.imageUrl, { asUri: true });

    const groups: QuestionResultToView['otherGroupBonusResults'] = useMemo(() => {
        return results.otherGroupBonusResults ?? [];
    }, [results.otherGroupBonusResults]);

    const itemSeparator = useCallback(() => <Spacer height={15} />, []);

    return (
        <Layout
            title="OTHER GROUP BONUS"
            titleStyle={styles.title}
            points={points}
            numberOfSteps={getNumberOfSteps(results)}
            currentStep={4}
            onBack={onBack}
            onNext={onNext}
            nextButtonColor="#FCAF41F4"
            topSectionStyle={styles.topSection}
            titleColor="#FCAF41F4"
            topSectionChildren={
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{results.guessQuestion}</Text>
                    <Image resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} style={styles.topSectionImage} source={imageUri} />
                </View>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <FlatList
                style={styles.list}
                data={groups}
                contentContainerStyle={styles.listContent}
                renderItem={({ item }) => (
                    <InfoCard
                        type={results.answerType}
                        title={item.groupType}
                        userValue={item.userGuess}
                        correctValue={item.surveyResult}
                        userImage={
                            <View style={styles.userImageContainer}>
                                <Image style={styles.userImage} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                            </View>
                        }
                        correctImage={
                            <View style={styles.logoContainer}>
                                <Image style={styles.logo} source={imageUtils.screens.logoHomeScreen} resizeMode="contain" />
                            </View>
                        }
                        userColor="#EE3823"
                        correctColor="#6A2C8C"
                        resultType={item.resultType}
                        min={results.answerOptions.min}
                        max={results.answerOptions.max}
                    />
                )}
                ItemSeparatorComponent={itemSeparator}
            />
        </Layout>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
    },
    topSection: {
        backgroundColor: '#FCAF41F4',
        height: '35%',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 20,
    }]),
    topSectionImage: {
        flex: 1,
        alignSelf: 'stretch',
        borderRadius: 12,
        marginHorizontal: 10,
    },
    bottomSection: {
        justifyContent: 'flex-start',
    },
    list: {
        flex: 1,
        alignSelf: 'stretch',
    },
    listContent: {
        padding: 15,
    },
    userImageContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 360,
        borderColor: '#D9D9D9',
    },
    userImage: {
        width: 31,
        height: 31,
        borderRadius: 360,
    },
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 360,
        borderColor: 'transparent',
    },
    logo: {
        width: 31,
        height: 31,
    },
});
