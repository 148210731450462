import React, { Fragment } from 'react';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import { AnswerType } from '@sapphicsavvy/business';
import CirculaireSlider from '../../../helperComponents/CirculaireSlider';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import BarSlider from '../../../helperComponents/BarSlider';

type SliderResultCardProps = {
    type: AnswerType,
    value: number,
    title: string,
    titleStyle?: StyleProp<TextStyle>,
    image?: React.ReactChild,
    min?: number,
    max?: number
    colors?: [string, string]
}

export default function SliderResultCard({ type, image, title, value, min, max, colors }: SliderResultCardProps) {

    return (
        <ShadowView style={styles.container}>
            <View>
                {image}
                <Text style={[styles.leftSectionTitleText, { color: colors?.[0] ?? '#000000' }]}>{title}</Text>
            </View>
            <View style={styles.content}>
                {type === AnswerType.percentage &&
                    <Fragment>
                        <CirculaireSlider
                            percentage={value}
                            enabled={false}
                            colors={colors}
                        />
                        <Text style={[styles.bottomPercentageText, { color: colors?.[0] ?? '#000000' }]}>{value}%</Text>
                    </Fragment>
                }
                {type === AnswerType.numeric &&
                    <Fragment>
                        <View style={styles.bar}>
                            <BarSlider colors={colors} minValue={min || 0} maxValue={max || 100} value={value} disableLimit enabled={false} />
                        </View>
                    </Fragment>
                }
            </View>
            {type === AnswerType.numeric && <Text style={[styles.valueText, { color: colors?.[0] }]}>{value}</Text>}
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignSelf: 'stretch',
    },
    leftSectionTitleText: mbTextStyles([textStyles.normalText, {
        fontWeight: '600',
        marginTop: 8,
    }]),
    content: {
        flex: 1,
    },
    bar: {
        flex: 1,
        marginLeft: 25,
        justifyContent: 'center',
    },
    bottomPercentageText: mbTextStyles([textStyles.normalText, {
        fontSize: 35,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 45,
    }]),
    valueText: mbTextStyles([textStyles.normalText, {
        fontSize: 35,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        marginLeft: 9,
    }]),
});
