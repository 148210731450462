import React from 'react';
import { GestureResponderEvent, Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewProps, ViewStyle } from 'react-native';

export type ShadowViewProps = ViewProps & {
    style?: StyleProp<ViewStyle>,
    topLeftShadowOffset?: {
        width: number,
        height: number,
    },
    topLeftShadowColor?: string,
    topLeftShadowOpacity?: number,
    topLeftShadowRadius?: number,

    bottomRightShadowOffset?: {
        width: number,
        height: number,
    },
    bottomRightShadowColor?: string,
    bottomRightShadowOpacity?: number,
    bottomRightShadowRadius?: number,

    children?: React.ReactNode

    onPress?: (e: GestureResponderEvent) => void,
    disabled?: boolean
    activeOpacity?: number
};

const ShadowView = ({
    style,
    topLeftShadowOffset = { width: -5, height: -5 },
    topLeftShadowColor = '#FFFFFF',
    topLeftShadowOpacity = 1,
    topLeftShadowRadius = Platform.OS === 'android' ? 15 : 5,
    bottomRightShadowOffset = { width: 5, height: 5 },
    bottomRightShadowColor = '#AEAEC0',
    bottomRightShadowOpacity = 0.5,
    bottomRightShadowRadius = Platform.OS === 'android' ? 15 : 5,
    children,
    ...viewProps
}: ShadowViewProps) => {

    const {
        padding,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingHorizontal,
        paddingVertical,
        paddingStart,
        paddingEnd,
        margin,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        marginHorizontal,
        marginVertical,
        marginStart,
        marginEnd,
        alignItems,
        justifyContent,
        alignContent,
        alignSelf,
        flexDirection,
        borderWidth,
        borderColor,
        ...pureStyles
    } = StyleSheet.flatten(style ?? {});

    const shadows = {
        topLeft: {
            shadowOffset: topLeftShadowOffset,
            shadowColor: topLeftShadowColor,
            shadowOpacity: topLeftShadowOpacity,
            shadowRadius: topLeftShadowRadius,
            elevation: topLeftShadowRadius,
            margin,
            marginTop,
            marginBottom,
            marginLeft,
            marginRight,
            marginHorizontal,
            marginVertical,
            marginStart,
            marginEnd,
            alignSelf,
        },
        bottomRight: {
            shadowOffset: bottomRightShadowOffset,
            shadowColor: bottomRightShadowColor,
            shadowOpacity: bottomRightShadowOpacity,
            shadowRadius: bottomRightShadowRadius,
            elevation: bottomRightShadowRadius,
            padding,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            paddingHorizontal,
            paddingVertical,
            paddingStart,
            paddingEnd,
            alignItems,
            justifyContent,
            alignContent,
            flexDirection,
            borderColor,
            borderWidth,
        },
    };

    if (viewProps.onPress) {
        if (Platform.OS === 'android') {
            return (
                <View style={[styles.container, pureStyles, shadows.topLeft]} {...viewProps}>
                    <TouchableOpacity
                        style={[styles.innerContainer, pureStyles, shadows.bottomRight]}
                        disabled={viewProps.disabled}
                        onPress={viewProps.onPress}
                        activeOpacity={viewProps.activeOpacity}
                    >
                        {children}
                    </TouchableOpacity>
                </View>
            );
        }
        return (
            <TouchableOpacity style={[styles.container, pureStyles, shadows.topLeft]} {...viewProps}>
                <View style={[styles.innerContainer, pureStyles, shadows.bottomRight]}>
                    {children}
                </View>
            </TouchableOpacity>
        );
    }

    return (
        <View style={[styles.container, pureStyles, shadows.topLeft]} {...viewProps}>
            <View style={[styles.innerContainer, pureStyles, shadows.bottomRight]}>
                {children}
            </View>
        </View>
    );
};

export { ShadowView };

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#F0F0F3',
        borderRadius: 10,
    },
    innerContainer: {
        backgroundColor: '#F0F0F3',
        borderRadius: 10,
    },
});

