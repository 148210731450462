import React, { useCallback, useState } from 'react';
import GradientContainer from './gradients/GradientContainer';
import { InnerShadow } from './InnerShadow';
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import Svg, { Circle } from 'react-native-svg';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';

type BarSliderProps = {
    value: number,
    minValue: number,
    maxValue: number,
    colors?: [string, string],
    disableLimit?: boolean,
    size?: number,
    steps?: number,
    onChange?: (value: number) => void,
    enabled?: boolean
}

function getClosestNumber(value: number, steps: number, forwardDirection = true) {
    if (value % steps === 0) {
        return value;
    }
    for (let i = 0; i < steps; i++) {
        if (forwardDirection && value - i % steps === 0) {
            return value - i;
        } else if (value + i % steps === 0) {
            return value + i;
        }
    }
}

export default function BarSlider({ value, minValue, maxValue, colors = ['#6A2C8C', '#C263F6'], disableLimit, size = 20, steps = 1, onChange, enabled = true }: BarSliderProps) {

    const [width, setWidth] = useState(0);
    const percentage = value * 100 / maxValue;
    const offset = percentage - ((size / 2) * 100 / width);

    const onLayout = useCallback((ev: LayoutChangeEvent) => {
        setWidth(ev.nativeEvent.layout.width);
    }, []);

    const gesture = Gesture
        .Pan()
        .enabled(enabled)
        .runOnJS(true)
        .onUpdate(ev => {
            const x = ev.x;
            const newPercentage = Math.ceil(x * 100 / width);
            if (newPercentage >= 0 && newPercentage <= 100) {
                const newValue = Math.floor(minValue + newPercentage * (maxValue - minValue) / 100);
                const closestValue = getClosestNumber(newValue, steps, ev.velocityX > 0);
                if (closestValue !== undefined) {
                    onChange?.(closestValue);
                }
            }
        });

    return (
        <GestureDetector gesture={gesture}>
            <View onLayout={onLayout}>
                <InnerShadow style={[styles.container, { height: size }]}>
                    <GradientContainer
                        style={[styles.gradient, { width: `${value * 100 / maxValue}%`, height: size * 90 / 100 }]}
                        colors={colors}
                        angle={360}
                    />
                </InnerShadow>
                {enabled &&
                    <Svg style={[styles.thumb, { height: size + 25 * size / 100, width: size + 25 * size / 100, left: `${offset}%` }]} viewBox="0 0 25 25">
                        <Circle fill="#D6E3F3" r={12} translateX={12.5} translateY={12.5} />
                        <Circle fill={colors[1]} r={11} translateX={12.5} translateY={12.5} />
                        <Circle fill="#D6E3F3" r={9} translateX={12.5} translateY={12.5} />
                        <Circle fill={colors[0]} r={8} translateX={12.5} translateY={12.5} />
                    </Svg>
                }
                {!disableLimit &&
                    <View style={styles.limits}>
                        <Text style={styles.limitLabel}>{minValue}</Text>
                        <Text style={styles.limitLabel}>{maxValue}</Text>
                    </View>
                }
            </View>
        </GestureDetector>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 20,
        borderWidth: 1,
        borderColor: mbApplyTransparency('#D6E3F3', 0.45),
        borderRadius: 20,
    },
    gradient: {
        height: 18,
        borderRadius: 8,
        overflow: 'hidden',
    },
    thumb: {
        position: 'absolute',
        top: -2,
    },
    limits: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 4,
    },
    limitLabel: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: '#7E8F9F',
        fontWeight: '800',
    }]),
});

