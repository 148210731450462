import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

type IconProps = {
    size?: number,
    color?: string
}

export function StandingWithNumberIcon({ size = 51, color = '#F7D345' }: IconProps) {
  return (
    <Svg
      width={size}
      height={size * (25 / 51)}
      viewBox="0 0 51 25"
      fill="none"
    >
      <G clipPath="url(#clip0_218_28411)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.942 6.88h13.19c.124 0 .246.023.36.066a.955.955 0 01.306.185c.088.079.157.173.204.277a.785.785 0 01.072.326v16.413a.785.785 0 01-.072.326.851.851 0 01-.204.277.954.954 0 01-.305.185 1.027 1.027 0 01-.36.065H.942c-.25 0-.49-.09-.667-.25A.815.815 0 010 24.146V7.735c0-.226.1-.443.276-.603a.994.994 0 01.666-.25zM28.39 3.55v8.106h-3.072V6.539l-1.876.676-.83-1.992 3.18-1.674h2.598zM10.79 18.592H4.565v-2.12l1.18-1.035c.256-.214.501-.425.734-.639l.577-.511c.155-.142.295-.298.415-.466a1.29 1.29 0 00.224-.376.966.966 0 00.037-.267.372.372 0 00-.036-.232.422.422 0 00-.171-.174c-.231-.071-.475-.1-.718-.087a10.97 10.97 0 00-1.793.136l-.287.048-.162-2.124a9.672 9.672 0 012.956-.455 4.079 4.079 0 012.44.636c.29.209.52.477.674.783.153.306.223.64.206.976.034.612-.11 1.221-.415 1.767-.395.541-.915.998-1.523 1.339l-.91.579h2.798v2.222zm32-4.61c1.333 0 2.302.172 2.905.515.603.344.905.952.905 1.824.018.352-.044.704-.183 1.034-.15.265-.373.49-.648.654.288.19.53.431.714.707.163.31.237.65.216.993 0 1.025-.294 1.733-.884 2.124-.59.391-1.5.585-2.73.583a12.84 12.84 0 01-2.412-.233l-.448-.087.062-2.17c1.043.084 1.786.125 2.229.125.296.013.594-.01.884-.068a.315.315 0 00.167-.113.265.265 0 00.053-.184.314.314 0 00-.031-.175.351.351 0 00-.123-.137 1.116 1.116 0 00-.569-.124h-1.917v-2.18h1.793c.49 0 .734-.125.734-.377s-.31-.376-.937-.376c-.415 0-1.05.03-1.876.087l-.353.026-.083-2.09c.82-.214 1.663-.34 2.515-.377l.017.02zM18.905 0h13.19c.25 0 .49.09.666.25.177.16.276.377.276.604v23.311c0 .227-.1.444-.276.604a.994.994 0 01-.666.25h-13.19c-.25 0-.49-.09-.666-.25a.815.815 0 01-.276-.604V.854c0-.227.1-.444.276-.604a.994.994 0 01.666-.25zm17.963 11.412h13.19c.25 0 .488.09.665.25.176.16.276.377.277.603v11.881c0 .227-.1.444-.276.604a.994.994 0 01-.666.25h-13.19c-.25 0-.49-.09-.666-.25a.815.815 0 01-.276-.604V12.265c0-.226.099-.444.276-.604a.994.994 0 01.666-.25z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_218_28411">
          <Path fill="#fff" d="M0 0H51V25H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
