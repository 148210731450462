import React, { useCallback } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { trpc } from '../../../apiCalls/trpcClient';
import { ShadowView } from '../../helperComponents/ShadowView';
import { Image, StyleSheet, Text, View } from 'react-native';
import { getTopInset } from '../../../utils/shared';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import { Spacer } from '../../helperComponents/Spacer';
import { imageUtils } from '../../../utils/imageUtils';
import CountDown from '../../helperComponents/CountDown';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../navigations/RootNavigator';
import { QuarterDataToPlay } from '@sapphicsavvy/business';
import { utils } from '../../../utils/utils';


const Home = () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const { data: haveToPlay, isLoading: isHaveToPlayLoading } = trpc.quarter.haveToPlay.useQuery();
    const { data: quarterData, isLoading: isQuarterLoading } = trpc.quarter.getQuarterDataToPlay.useQuery({
        quarterId: haveToPlay?.quarterId || '',
    }, { enabled: haveToPlay !== undefined });

    const onButtonPress = useCallback(() => {
        if (quarterData && !haveToPlay?.isPlayed) {
            const questionIndex = quarterData.questions.findIndex(question => question.id === haveToPlay?.questionId);
            navigation.navigate('Quarter', {
                quarterData: quarterData as QuarterDataToPlay,
                questionIndex,
                step: 'Guess',
                answer: {
                    questionId: quarterData.questions[questionIndex].id,
                    answerType: quarterData.questions[questionIndex].answerType,
                },
                answers: [],
            });
        } else {
            utils.showPopUp('Coming Soon!');
        }
    }, [haveToPlay, navigation, quarterData]);

    console.log(haveToPlay, '---------', quarterData)

    return (
        <ComponentWrapper
            containerStyle={[sharedStyles.container, styles.container]}
        >
            <ShadowView style={styles.quarterCard}>
                <ShadowView style={styles.quarterCardInner}>
                    <Image
                        style={styles.logo}
                        source={imageUtils.screens.logoHomeScreen}
                        resizeMode="cover"
                    />
                    <Text style={styles.quarterCardTitleText}>QUARTER {quarterData?.quarterNumber}</Text>
                    <Text style={styles.quarterCardText}>CLOSES IN</Text>
                    <Spacer height={10} />
                    <View>
                        <View style={styles.colors}>
                            <Spacer height={20} color="#0495CD" />
                            <Spacer height={20} color="#6A2C8C" />
                            <Spacer height={20} color="#EE3823" />
                            <Spacer height={20} color="#FCAF41" />
                        </View>
                        {(isHaveToPlayLoading || isQuarterLoading) ?
                            <View style={styles.loading}>
                                <Text style={styles.loadingText}>Loading</Text>
                            </View>
                            :
                            <CountDown
                                labelsStyle={styles.countdownLabels}
                                style={styles.countdown}
                                endTime={quarterData?.endTimestamp ?? 0}
                            />
                        }
                    </View>
                </ShadowView>
            </ShadowView>
            <GradientButton
                title="START"
                containerStyle={styles.startBtn}
                disabled={isHaveToPlayLoading || isQuarterLoading || haveToPlay?.isPlayed}
                onPress={onButtonPress}
            />
        </ComponentWrapper>
    );

};

export { Home };

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset() + 60,
    },
    logo: {
        position: 'absolute',
        width: 85,
        height: 97,
        alignSelf: 'center',
        top: -60,
    },
    quarterCard: {
        padding: 15,
    },
    quarterCardInner: {
        paddingTop: 40,
        paddingBottom: 25,
    },
    quarterCardTitleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'center',
        fontSize: 38,
        color: '#000000',
    }]),
    quarterCardText: mbTextStyles([{
        fontFamily: FONT_STYLES.Staatliches_400,
        textAlign: 'center',
        fontSize: 18,
        color: '#EE3823',
        marginBottom: 5,
    }]),
    colors: {
        position: 'absolute',
        top: -10,
        bottom: 0,
        left: 0,
        right: 0,
    },
    countdown: {
        paddingHorizontal: 30,
    },
    loading: {
        height: 90,
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 20,
        fontWeight: '400',
        marginBottom: 30,
    }]),
    countdownLabels: {
        marginTop: 10,
    },
    startBtn: {
        width: 227,
        alignSelf: 'center',
        marginTop: 20,
    },
});
