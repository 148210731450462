import React, { Fragment } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import OveviewScreen from '../../../helperComponents/OveviewScreen';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import PointsCard from '../../../helperComponents/PointsCard';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';

type PointsSummaryProps = {
    quarterNumber?: number,
    quarterPoints?: number,
    sleuthPoints?: number
    onPress?: () => void,
}

export default function PointsSummary({ quarterNumber, quarterPoints, sleuthPoints, onPress }: PointsSummaryProps) {

    const totalPoints = (quarterPoints || 0) + (sleuthPoints || 0);

    return (
        <OveviewScreen
            topSectionStyle={styles.topSection}
            gardientColors={['#EF3A24', '#F9943B']}
            topSectionChildren={
                <Fragment>
                    <Text style={styles.topTitleText}>QUARTER {quarterNumber}</Text>
                    <Text style={styles.titleText}>POINTS SUMMARY</Text>
                    <View>
                        <CoinsIcon size={68} />
                    </View>
                </Fragment>
            }
        >
            <View style={styles.content}>
                <PointsCard
                    title="Points at completion"
                    points={quarterPoints || 0}
                    backgroundColor="#F9EFFF"
                    textColor="#6A2C8C"
                />
                <View style={styles.plus}>
                    <Text style={styles.plusText}>+</Text>
                </View>
                <PointsCard
                    title="Sleuth Points"
                    points={sleuthPoints || 0}
                    backgroundColor="#FFF9F1"
                    textColor="#FCAF41F4"
                />
                <PointsCard
                    title="Total Points"
                    points={totalPoints}
                    backgroundColor="transparent"
                    textColor="#000000"
                    disableBorder
                    disableShadow
                />
                <GradientButton
                    title="VIEW RESULTS"
                    containerStyle={styles.btn}
                    onPress={onPress}
                />
            </View>
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 160,
        borderColor: '#FFFFFF',
        marginTop: 30,
    },
    image: {
        width: 181,
        height: 181,
        borderRadius: 160,
    },
    topTitleText: mbTextStyles([textStyles.largestText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
    }]),
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 38,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        marginBottom: 20,
    }]),
    content: {
        paddingHorizontal: 40,
        paddingBottom: 60,
        paddingTop: 20,
        flex: 1,
    },
    plus: {
        flexDirection: 'row',
        height: 1,
        zIndex: 2,
    },
    plusText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#F35E2E',
        fontWeight: '400',
        position: 'absolute',
        top: -37,
        right: 100,
    }]),
    btn: {
        width: 227,
        alignSelf: 'center',
        marginTop: 'auto',
    },
});
