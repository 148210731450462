import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SynthesisIcon() {
  return (
    <Svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.002.077h17.165v20.467c-.03.5-.684.512-1.392.48H6.761c-1.604.145-1.033 2.26 0 2.15h16.666v-.73h1.39v1.236a.79.79 0 01-.235.561.806.806 0 01-.568.233H6.831c-2.406.034-3.199-.093-3.199-1.837v-2.526H1.795c-.513 0-.914-.463-.914-1.031 0-.568.401-1.03.914-1.03h1.837v-1.645H1.795c-.513 0-.914-.461-.914-1.031 0-.57.401-1.029.914-1.029h1.837V12.7H1.795c-.513 0-.914-.461-.914-1.03 0-.567.401-1.03.914-1.03h1.837V9.005H1.795c-.513 0-.914-.471-.914-1.041s.401-1.031.914-1.031h1.837V5.289H1.795c-.513 0-.914-.455-.914-1.03 0-.573.401-1.03.914-1.03h1.837C3.628.593 3.594-.026 7.002.077zm3.223 8.314a.464.464 0 01-.327-.134.455.455 0 01.327-.78h6.78c.123 0 .24.048.326.134a.454.454 0 010 .646.463.463 0 01-.326.134h-6.78zm0-2.407a.465.465 0 01-.308-.145.456.456 0 01.308-.77h7.998a.467.467 0 01.455.274.455.455 0 01-.27.615.467.467 0 01-.186.026h-7.998zM9.04 3.046h10.367a.675.675 0 01.47.195.662.662 0 01.198.467V9.76a.666.666 0 01-.198.467.68.68 0 01-.47.197H9.04a.675.675 0 01-.471-.196.662.662 0 01-.197-.468V3.698a.66.66 0 01.2-.461.673.673 0 01.468-.19z"
        fill="#6A2C8C"
      />
    </Svg>
  );
}
