import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type ReplayIconProps = {
    size?: number,
    color?: string
}

export default function ReplayIcon({ size = 35, color = '#0295CD' }: ReplayIconProps) {
    return (
        <Svg
            width={size}
            height={size * (36 / 35)}
            viewBox="0 0 35 36"
            fill="none"
        >
            <Path
                d="M19.767 13.21l-.196-.08a5.78 5.78 0 00-2.058-.374 5.763 5.763 0 00-4.087 1.692l-.053.05a5.76 5.76 0 00-1.64 4.037c0 1.596.648 3.042 1.694 4.087a5.763 5.763 0 004.086 1.692 5.758 5.758 0 003.904-1.514l.06-.052a5.76 5.76 0 001.795-3.705 1.122 1.122 0 112.237.184 8.014 8.014 0 01-2.51 5.159l-.064.062a7.998 7.998 0 01-5.422 2.112 8.001 8.001 0 01-5.674-2.35 8.005 8.005 0 01-2.35-5.675 8 8 0 012.29-5.61l.06-.064a8.001 8.001 0 015.674-2.351 8.017 8.017 0 013.217.673l-.359-.995a1.12 1.12 0 012.106-.755l1.273 3.531a1.118 1.118 0 01-.675 1.43l-3.706 1.337a1.119 1.119 0 01-.755-2.105l1.153-.416zM17.5.69c4.831 0 9.207 1.959 12.374 5.125A17.45 17.45 0 0135 18.189c0 4.827-1.96 9.203-5.128 12.372A17.433 17.433 0 0117.5 35.69a17.45 17.45 0 01-12.374-5.127A17.449 17.449 0 010 18.19 17.45 17.45 0 015.126 5.814 17.448 17.448 0 0117.5.689zM28.258 7.43A15.166 15.166 0 0017.5 2.975 15.165 15.165 0 006.742 7.431 15.168 15.168 0 002.286 18.19c0 4.201 1.703 8.006 4.456 10.758A15.168 15.168 0 0017.5 33.404c4.201 0 8.006-1.704 10.758-4.457a15.149 15.149 0 004.456-10.758c0-4.2-1.703-8.005-4.456-10.758z"
                fill={color}
            />
        </Svg>
    );
}
