import React, { Fragment, useCallback, useState } from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { getNumberOfSteps, getTopInset, NotNull } from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import Stepper from '../../../helperComponents/Stepper';
import BarSlider from '../../../helperComponents/BarSlider';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { AnswerType, QuestionToPlay } from '@sapphicsavvy/business';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { Answer } from '../../../../navigations/RootNavigator';

type BetStepProp = {
    onNext?: (answer: Partial<Answer>) => void,
    onBack?: () => void,
    question: QuestionToPlay,
    isSubmitting?: boolean,
}

export default function AnswerStep({ question, onNext, onBack, isSubmitting }: BetStepProp) {

    const { currentUserData } = useSignedInContext();

    const [value, setValue] = useState<number>();

    const imageUri = mbGetMediumImage(question.imageUrl, { asUri: true });

    const min = question.answerOptions.min || 0;
    const max = question.answerOptions.max || 100;
    const increment = question.answerOptions.increment || 1;

    const onPlus = useCallback(
        () => setValue(prev => Math.min((prev || 0) + increment, max)),
        [increment, max]
    );

    const onMinus = useCallback(
        () => setValue(prev => Math.max((prev || 0) - increment, min)),
        [increment, min]
    );

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>ANSWER</Text>
                <Spacer />
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>200</Text>
                </ShadowView>
            </View>
            <View style={styles.topSection}>
                <Stepper style={styles.topSectionStepper} step={3} max={getNumberOfSteps(question)} />
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{question.sapphicQuestion}</Text>
                    <Image resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} style={styles.topSectionImage} source={imageUri} />
                </View>
                <View style={styles.topSectionBottom} />
            </View>
            <View style={styles.bottomSection}>
                <View style={styles.imageConatiner}>
                    <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                </View>
                {question.answerType === AnswerType.numeric ?
                    <Fragment>
                        <View style={{ alignSelf: 'stretch', paddingHorizontal: 30, paddingTop: 30 }}>
                            <BarSlider colors={['#F35E2E', '#F35E2E']} minValue={min} value={value ?? min} maxValue={max} steps={increment} onChange={setValue} />
                            <Text style={styles.bottomPercentageText}>{value !== undefined ? value : '?'}</Text>
                        </View>
                        <View style={styles.bottomSectionBtns}>
                            <ShadowRoundedButton
                                size={74}
                                borderWidth={20}
                                icon={<MinusSignIcon size={21} />}
                                onPress={onMinus}
                            />
                            <Spacer width={30} />
                            <ShadowRoundedButton
                                size={74}
                                borderWidth={20}
                                icon={<PlusSignIcon size={21} />}
                                onPress={onPlus}
                            />
                        </View>
                    </Fragment>
                    :
                    <View style={styles.bottomSectionAnswerBtns}>
                        <ShadowView
                            style={[styles.bottomSectionAnswerBtn, value === 0 && styles.activeBtn]}
                            onPress={() => setValue(0)}
                        >
                            <Text style={[styles.bottomSectionAnswerBtnText, value === 0 && styles.activeText]}>NO</Text>
                        </ShadowView>
                        <Spacer width={15} />
                        <ShadowView
                            style={[styles.bottomSectionAnswerBtn, value === 1 && styles.activeBtn]}
                            onPress={() => setValue(1)}
                        >
                            <Text style={[styles.bottomSectionAnswerBtnText, value === 1 && styles.activeText]}>YES</Text>
                        </ShadowView>
                    </View>
                }
            </View>
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={styles.nextBtn}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={() => onNext?.({ answer: value })}
                    disabled={!NotNull(value) || isSubmitting}
                    loading={isSubmitting}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset(),
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 38,
        color: '#F4602F',
    }]),
    topSection: {
        height: '50%',
        backgroundColor: '#F4602F',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingBottom: 80,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionImage: {
        width: '100%',
        flex: 1,
        borderRadius: 12,
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 21,
    }]),
    topSectionBottom: {
        height: 20,
        marginTop: 'auto',
        backgroundColor: mbApplyTransparency('#000000', 0.12),
    },
    topSectionTitleBoldText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontWeight: '700',
        marginVertical: 14,
    }]),
    bottomSection: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        position: 'absolute',
        top: -80,
    },
    image: {
        width: 116,
        height: 116,
        borderRadius: 80,
    },
    bottomPercentageText: mbTextStyles([textStyles.normalText, {
        fontSize: 45,
        fontWeight: '700',
        color: '#F35E2E',
        marginBottom: 15,
    }]),
    bottomSectionBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: -15,
    },
    bottomSectionAnswerBtns: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        justifyContent: 'center',
        marginTop: '-10%',
    },
    bottomSectionAnswerBtn: {
        width: 158,
        height: 74,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
    },
    activeBtn: {
        backgroundColor: '#EFE7E8',
    },
    bottomSectionAnswerBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 25,
        fontWeight: '700',
        color: '#46596B',
    }]),
    activeText: {
        color: '#F3522B',
    },
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: '#F4602F',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnDisabled: {
        backgroundColor: mbApplyTransparency('#F4602F', 0.70),
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
